import * as React from "react";
import { useState } from "react";
import { Theme, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import makeStyles from "@mui/styles/makeStyles";
import Topbar from "../Nav/Topbar";
import Sidebar from "../Nav/Sidebar";
import Footer from "../Nav/Footer";
import clsx from "clsx";
import { MeProvider, MeConsumer } from "../../../context/MeContext";

type Props = {
  title?: string;
  textAlign?: any;
  children?: React.ReactNode;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: 56,
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      paddingTop: 64,
    },
  },
  shiftContent: {
    paddingLeft: 240,
  },
  content: {
    padding: theme.spacing(4),
    minHeight: "100%",
  },
  title: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("lg")]: {
      textAlign: "center",
    },
  },
}));

const DashboardLayout: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ children, title, textAlign }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true,
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop,
      })}
    >
      <MeProvider>
        <MeConsumer>
          {({ loading, error }) => {
            return loading ||
              (error && error.message.includes("Permission denied")) ? (
              <CircularProgress />
            ) : (
              <div style={{ position: "relative" }}>
                <Box sx={{ display: "flex" }}>
                  <Topbar onSidebarOpen={handleSidebarOpen} />
                  <Sidebar
                    onClose={handleSidebarClose}
                    open={shouldOpenSidebar}
                    variant={isDesktop ? "persistent" : "temporary"}
                  />
                </Box>
                <main className={classes.content}>
                  {title ? (
                    <Typography
                      variant="h1"
                      className={classes.title}
                      align={textAlign}
                    >
                      {title}
                    </Typography>
                  ) : undefined}
                  {children}
                </main>
                <Footer />
              </div>
            );
          }}
        </MeConsumer>
      </MeProvider>
    </div>
  );
};

export default DashboardLayout;
