const mimeType = {
  // MimeType
  // --------
  MIME_TYPE_GIF: "image/gif",
  MIME_TYPE_PNG: "image/png",
  MIME_TYPE_SVG: "image/svg+xml",
  MIME_TYPE_JPEG: "image/jpeg",
  MIME_TYPE_MP4: "video/mp4",
} as const;

const department = {
  DEPARTMENT_IT: "it",
  DEPARTMENT_ACCOUNT: "account",
  DEPARTMENT_SALES: "sales",
  DEPARTMENT_GRAPHIC_DESIGN: "graphic_design",
  DEPARTMENT_PACKAGING: "packaging",
  DEPARTMENT_STORAGE: "storage",
  DEPARTMENT_DELIVERY: "delivery",
  DEPARTMENT_PRODUCTION: "production",
  DEPARTMENT_FINANCIAL: "financial",
  DEPARTMENT_HUMAN_RESOURCE: "hr",
  DEPARTMENT_MANAGEMENT: "management",
  DEPARTMENT_CLIENT: "client", // Special department to calter if future, the system allow customer to login in
  DEPARTMENT_CONSUMER: "consumer",
} as const;

const gender = {
  USER_GENDER_MALE: "male",
  USER_GENDER_FEMALE: "female",
  USER_GENDER_OTHER: "other",
} as const;

const conditions = {
  CONDITIONS_EQUAL_TO: "equal",
  CONDITIONS_NOT_EQUAL_TO: "not_equal",
  CONDITIONS_CONTAIN: "contain",
  CONDITIONS_GREATER_THAN: "greater",
  CONDITIONS_GREATER_THAN_EQUAL: "greater_equal",
  CONDITIONS_LESS_THAN: "less",
  CONDITIONS_LESS_THAN_EQUAL: "less_equal",
  CONDITIONS_IN: "in",
  CONDITIONS_NOT_IN: "not_in",
  CONDITIONS_GEOWITHIN_GEOMETRY: "geowithin_geometry",
  CONDITIONS_GEOWITHIN_BOX: "geowithin_box",
  CONDITIONS_GEOWITHIN_POLYGON: "geowithin_polygon",
  CONDITIONS_GEOWITHIN_CENTER: "geowithin_center",
  CONDITIONS_GEOWITHIN_CENTER_SPHERE: "geowithin_center_sphere",
} as const;

const log = {
  LOG_ENTITY_USER: "user",
  LOG_ENTITY_ROLE: "role",
  LOG_ENTITY_ATTENDANCE: "attendance",
  LOG_ENTITY_ANNOUNCEMENT: "announcement",
  LOG_ENTITY_FILE: "file",
  LOG_ENTITY_WORKSHIFT: "workshift",
  LOG_ENTITY_CLOCK_LOCATION: "clocklocation",

  LOG_ACTION_CREATE: "create",
  LOG_ACTION_READ: "read",
  LOG_ACTION_UPDATE: "update",
  LOG_ACTION_DELETE: "delete",
  LOG_ACTION_NOTE: "note",

  LOG_STATUS_ACTIVE: "active",
  LOG_STATUS_DELETED: "deleted",
} as const;

const location = {
  LOCATION_TYPE_POINT: "Point",
  LOCATION_TYPE_POLYGON: "Polygon",
  LOCATION_TYPE_LINESTRING: "LineString",
  LOCATION_TYPE_MULTIPOINT: "MultiPoint",
  LOCATION_TYPE_MULTILINESTRING: "MultiLineString",
  LOCATION_TYPE_MULTIPOLYGON: "MultiPolygon",
  LOCATION_TYPE_GEOMETRYCOLLECTION: "GeometryCollection",
} as const;

export default {
  // Common
  // ------
  ISO_MY: "RM",
  CURRENCY_MY: "MYR",
  COUNTRY_MALAYSIA: "Malaysia",

  DATE_FORMAT: "YYYY-MM-DD",

  // User
  // ----
  USER_LOGIN_EMAIL: "email",
  USER_LOGIN_FACEBOOK: "facebook",
  USER_LOGIN_GMAIL: "gmail",

  USER_SURF_FROM_WEB: "web",
  USER_SURF_FROM_MOBILE: "mobile",
  USER_SURF_FROM_API: "api",

  USER_STATUS_ACTIVE: "active",
  USER_STATUS_PENDING: "pending",
  USER_STATUS_BAN: "ban",
  USER_STATUS_DELETED: "deleted",

  USER_GENDER_MALE: gender.USER_GENDER_MALE,
  USER_GENDER_FEMALE: gender.USER_GENDER_FEMALE,
  USER_GENDER_OTHER: gender.USER_GENDER_OTHER,

  USER_GENDERS: [
    gender.USER_GENDER_MALE,
    gender.USER_GENDER_FEMALE,
    gender.USER_GENDER_OTHER,
  ],

  USER_SALT_ROUND: 10,

  USER_USERNAME_MIN_LENGTH: 8,
  USER_USERNAME_MAX_LENGTH: 30,
  USER_PASSWORD_MIN_LENGTH: 8,
  USER_PASSWORD_MAX_LENGTH: 30,

  USER_ACCOUNT_TYPE_PERSONAL: "personal",
  USER_ACCOUNT_TYPE_BUSINESS: "business",

  // Role
  // ----
  ROLE_CONSUMER_USER: "consumer",
  ROLE_CUSTOMER_USER: "customer",
  ROLE_INTERNAL_USER: "internal",
  ROLE_LEADER_USER: "leader",
  ROLE_SUPERVISOR_USER: "supervisor",
  ROLE_MANAGER: "manager",
  ROLE_EXECUTER: "executer",
  ROLE_ADMIN: "admin",

  ROLE_TARGET_SELF: "self",
  ROLE_TARGET_OTHER: "other",

  ROLE_ACTION_ACCOUNT_MANAGEMENT: "account",
  ROLE_ACTION_FILE_MANAGEMENT: "file",
  ROLE_ACTION_NOTIFICATION_MANAGEMENT: "notification",
  ROLE_ACTION_ANNOUNCEMENT_MANAGEMENT: "announcement",
  ROLE_ACTION_ROLE_MANAGEMENT: "role",
  ROLE_ACTION_ATTENDANCE_MANAGEMENT: "attendance",
  ROLE_ACTION_LOG_MANAGEMENT: "log",
  ROLE_ACTION_CLOCK_LOCATION_MANAGEMENT: "clocklocation",
  ROLE_ACTION_WORKSHIFT_MANAGEMENT: "workshift",

  ROLE_STATUS_ACTIVE: "active",
  ROLE_STATUS_DELETED: "deleted",

  // Subscription
  // ------------
  SUBSCRIPTION_NOTIFICATION: "NOTIFICATION",
  SUBSCRIPTION_ANNOUNCEMENT: "ANNOUNCEMENT",
  SUBSCRIPTION_LOG: "LOG",

  SUBSCRIPTION_ANNOUNCEMENT_NEW: "NEW",
  SUBSCRIPTION_ANNOUNCEMENT_UPDATE: "UPDATE",

  // Notification
  // ------------
  NOTIFICATION_STATUS_READ: 1,
  NOTIFICATION_STATUS_NEW: 0,
  NOTIFICATION_STATUS_REMOVED: -1,

  NOTIFICATION_LINK_TO_PROFILE: "profile",
  NOTIFICATION_LINK_TO_PROJECT: "project",

  // Announcement
  // ------------
  ANNOUNCEMENT_STATUS_NEW: 1,
  ANNOUNCEMENT_STATUS_EXPIRED: 0,

  // Department
  // ----------
  DEPARTMENT_IT: department.DEPARTMENT_IT,
  DEPARTMENT_ACCOUNT: department.DEPARTMENT_ACCOUNT,
  DEPARTMENT_SALES: department.DEPARTMENT_SALES,
  DEPARTMENT_GRAPHIC_DESIGN: department.DEPARTMENT_GRAPHIC_DESIGN,
  DEPARTMENT_PACKAGING: department.DEPARTMENT_PACKAGING,
  DEPARTMENT_STORAGE: department.DEPARTMENT_STORAGE,
  DEPARTMENT_DELIVERY: department.DEPARTMENT_DELIVERY,
  DEPARTMENT_PRODUCTION: department.DEPARTMENT_PRODUCTION,
  DEPARTMENT_FINANCIAL: department.DEPARTMENT_FINANCIAL,
  DEPARTMENT_HUMAN_RESOURCE: department.DEPARTMENT_HUMAN_RESOURCE,
  DEPARTMENT_MANAGEMENT: department.DEPARTMENT_MANAGEMENT,
  DEPARTMENT_CLIENT: department.DEPARTMENT_CLIENT, // Special department to calter if future, the system allow customer to login in
  DEPARTMENT_CONSUMER: department.DEPARTMENT_CONSUMER,

  DEPARTMENTS: [
    {
      department: department.DEPARTMENT_IT,
      text: "IT",
    },
    {
      department: department.DEPARTMENT_ACCOUNT,
      text: "Account",
    },
    {
      department: department.DEPARTMENT_SALES,
      text: "Sales",
    },
    {
      department: department.DEPARTMENT_GRAPHIC_DESIGN,
      text: "Graphic Design",
    },
    {
      department: department.DEPARTMENT_PACKAGING,
      text: "Packing",
    },
    {
      department: department.DEPARTMENT_STORAGE,
      text: "Storage",
    },
    {
      department: department.DEPARTMENT_DELIVERY,
      text: "Delivery",
    },
    {
      department: department.DEPARTMENT_PRODUCTION,
      text: "Production",
    },
    {
      department: department.DEPARTMENT_FINANCIAL,
      text: "Financial",
    },
    {
      department: department.DEPARTMENT_HUMAN_RESOURCE,
      text: "Human Resource",
    },
    {
      department: department.DEPARTMENT_MANAGEMENT,
      text: "Management",
    },
    {
      department: department.DEPARTMENT_CLIENT,
      text: "Client",
    },
    {
      department: department.DEPARTMENT_CONSUMER,
      text: "Consumer",
    },
  ],

  // Prefix
  // ------
  PREFIX_USER_CONFIRMATION_TOKEN: "user-confirmation:",
  PREFIX_FORGET_PASSWORD_TOKEN: "forget-password:",

  PREFIX_ATTENDANCE_TOKEN: "attendance:",

  // MimeType
  // --------
  MIME_TYPE_GIF: mimeType.MIME_TYPE_GIF,
  MIME_TYPE_PNG: mimeType.MIME_TYPE_PNG,
  MIME_TYPE_JPEG: mimeType.MIME_TYPE_JPEG,
  MIME_TYPE_SVG: mimeType.MIME_TYPE_SVG,
  MIME_TYPE_MP4: mimeType.MIME_TYPE_MP4,

  FILES_MIMETYPE_IMAGE: [
    mimeType.MIME_TYPE_GIF,
    mimeType.MIME_TYPE_PNG,
    mimeType.MIME_TYPE_JPEG,
    mimeType.MIME_TYPE_SVG,
  ],

  FILES_MIMETYPE_VIDEO: [mimeType.MIME_TYPE_MP4],

  // Attendance
  // ----------
  ATTENDANCE_STATUS_ACTIVE: "active",
  ATTENDANCE_STATUS_DELETED: "deleted",

  ATTENDANCE_SCAN_CLOCK_STATUS_IN: "in",
  ATTENDANCE_SCAN_CLOCK_STATUS_OUT: "out",
  ATTENDANCE_SCAN_CLOCK_STATUS_EARLY_IN: "early-in",
  ATTENDANCE_SCAN_CLOCK_STATUS_EARLY_OUT: "early-out",
  ATTENDANCE_SCAN_CLOCK_STATUS_LATE_IN: "late-in",
  ATTENDANCE_SCAN_CLOCK_STATUS_LATE_OUT: "late-out",

  // WorkShift
  // ---------
  WORKSHIFT_STATUS_ACTIVE: "active",
  WORKSHIFT_STATUS_DELETED: "deleted",

  WORKSHIFT_DEFAULT_DATE: "2021-01-01",

  // Clock Location
  // --------------
  CLOCK_LOCATION_STATUS_ACTIVE: "active",
  CLOCK_LOCATION_STATUS_DELETED: "deleted",

  // Conditions
  // ----------
  ...conditions,

  // Log
  // ---
  ...log,

  // Location
  // --------
  ...location,

  // Util
  // ----
  QUERY_MAX_PER_PAGINATION: 1000,

  // Regex
  // -----
  REGEX_VALID_EMAIL:
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
  REGEX_VALID_PASSWORD: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,30}$/g,
  REGEX_VALID_USERNAME: /^[a-zA-Z0-9_\.]{8,30}$/g,
  REGEX_VALID_TAG: /@[a-zA-Z0-9_\.]{3,}/g,
  REGEX_VALID_HASHTAG: /#([^\u0000-\u007F]|[a-zA-Z0-9_\.])+/g, // <-- To allow multilanguage tag:  https://stackoverflow.com/questions/150033/regular-expression-to-match-non-ascii-characters
};
