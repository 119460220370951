import * as React from "react";
import { useRouter } from "next/router";
import clsx from "clsx";

import { Theme } from "@mui/material/styles";
import { blueGrey } from "@mui/material/colors";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

import Input from "@mui/icons-material/Input";
import Notifications from "@mui/icons-material/Notifications";

import makeStyles from "@mui/styles/makeStyles";
import MyLink from "../MyLink";
import { Permission } from "../../../interfaces/Permission";
import AccessRight from "../AccessRight";

type PageProps = {
  title: string;
  href: string;
  icon: JSX.Element;
  permission?: Permission;
};

type Props = {
  className?: string;
  pages: PageProps[];
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: blueGrey[800],
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightMedium,
  },
  icon: {
    color: theme.palette.text.secondary,
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1),
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    "& $icon": {
      color: theme.palette.primary.main,
    },
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}));

const SidebarNav: React.FunctionComponent<React.PropsWithChildren<Props>> = (
  props
) => {
  const { pages, className, ...rest } = props;

  const classes = useStyles();
  const router = useRouter();

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      {pages.map((page) => {
        const child = (
          <ListItem className={classes.item} disableGutters key={page.title}>
            <MyLink
              href={page.href}
              classes={{ root: classes.button }}
              underline="none"
              variant="button"
              component={Button}
            >
              <div
                style={{ display: "flex", flexGrow: 1, alignItems: "center" }}
                className={
                  router &&
                  router.pathname &&
                  router.pathname === "/" &&
                  page.href === "/"
                    ? classes.active
                    : page.href !== "/" &&
                      router &&
                      router.pathname &&
                      router.pathname.startsWith(page.href)
                    ? classes.active
                    : undefined
                }
              >
                <div className={classes.icon}>{page.icon}</div>
                {page.title}
              </div>
            </MyLink>
          </ListItem>
        );

        return page.permission ? (
          <AccessRight permission={page.permission} key={page.title}>
            {child}
          </AccessRight>
        ) : (
          child
        );
      })}
      <Box sx={{ display: { lg: "none", xs: "block" } }}>
        <Divider className={classes.divider} />
        <ListItem className={classes.item} disableGutters key="notifications">
          <MyLink
            href={"/notifications"}
            classes={{ root: classes.button }}
            underline="none"
            variant="button"
            component={Button}
          >
            <div
              style={{ display: "flex", flexGrow: 1, alignItems: "center" }}
              className={
                router &&
                router.pathname &&
                router.pathname.startsWith("/notifications")
                  ? classes.active
                  : undefined
              }
            >
              <div className={classes.icon}>
                <Notifications />
              </div>
              Notifications
            </div>
          </MyLink>
        </ListItem>
        <ListItem className={classes.item} disableGutters key="logout">
          <MyLink
            href={"/logout"}
            classes={{ root: classes.button }}
            underline="none"
            variant="button"
            component={Button}
          >
            <div
              style={{ display: "flex", flexGrow: 1, alignItems: "center" }}
              className={
                router &&
                router.pathname &&
                router.pathname.startsWith("/logout")
                  ? classes.active
                  : undefined
              }
            >
              <div className={classes.icon}>
                <Input />
              </div>
              Logout
            </div>
          </MyLink>
        </ListItem>
      </Box>
    </List>
  );
};

export default SidebarNav;
