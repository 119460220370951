import CircularProgress from "@mui/material/CircularProgress";
import { PropsWithChildren, useContext } from "react";
import constants from "../../constants";
import MeContext from "../../context/MeContext";
import { Role, RoleCrud, RoleTarget } from "../../generated/apolloComponents";
import RecordNotFound from "./RecordNotFound";
import { isEmpty } from "lodash";
import redirect from "../../lib/redirect";
import { Permission } from "../../interfaces/Permission";
import { useRouter } from "next/router";

interface Props extends PropsWithChildren {
  permission?: Permission;
  showError?: boolean;
  redirectTo?: string;
}

const isValidRole = (role: Role, permission: Permission) => {
  let isValidRole = false;

  if (role) {
    if (!isEmpty(permission.action) || !isEmpty(permission.targets)) {
      let roleTarget: RoleTarget | undefined = undefined;
      let roleCrud: RoleCrud | undefined = undefined;

      switch (permission.action) {
        case constants.ROLE_ACTION_ACCOUNT_MANAGEMENT:
          roleTarget = role.permissions?.accountManagement;
          break;
        case constants.ROLE_ACTION_FILE_MANAGEMENT:
          roleTarget = role.permissions?.fileManagement;
          break;
        case constants.ROLE_ACTION_NOTIFICATION_MANAGEMENT:
          roleTarget = role.permissions?.notificationManagement;
          break;
        case constants.ROLE_ACTION_ANNOUNCEMENT_MANAGEMENT:
          roleTarget = role.permissions?.announcementManagement;
          break;
        case constants.ROLE_ACTION_ATTENDANCE_MANAGEMENT:
          roleTarget = role.permissions?.attendanceManagement;
          break;
        case constants.ROLE_ACTION_ROLE_MANAGEMENT:
          roleTarget = role.permissions?.roleManagement;
          break;
        case constants.ROLE_ACTION_CLOCK_LOCATION_MANAGEMENT:
          roleTarget = role.permissions?.clockLocationManagement;
          break;
        case constants.ROLE_ACTION_WORKSHIFT_MANAGEMENT:
          roleTarget = role.permissions?.workShiftManagement;
          break;
        case constants.ROLE_ACTION_LOG_MANAGEMENT:
          roleTarget = role.permissions?.logManagement;
          break;
      }

      permission.targets.forEach((target) => {
        if (!isValidRole) {
          if (roleTarget) {
            switch (target.target) {
              case constants.ROLE_TARGET_SELF:
                roleCrud = roleTarget.self;
                break;
              case constants.ROLE_TARGET_OTHER:
                roleCrud = roleTarget.other;
                break;
            }
          }

          if (roleCrud && target.crud) {
            if (
              (target.crud.create && target.crud.create !== roleCrud.create) ||
              (target.crud.read && target.crud.read !== roleCrud.read) ||
              (target.crud.update && target.crud.update !== roleCrud.update) ||
              (target.crud.delete && target.crud.delete !== roleCrud.delete)
            ) {
              isValidRole = false;
            } else {
              isValidRole = true;
            }
          } else if (!target.crud) {
            isValidRole = true;
          }
        }
      });
    }
  }

  return isValidRole;
};

const AccessRight: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  children,
  permission,
  showError,
  redirectTo,
}) => {
  if (!permission) {
    return <>{children}</>;
  }

  const { loading, data, error } = useContext(MeContext);
  const router = useRouter();

  // console.log(data);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <RecordNotFound
        title={"Oops! Error authenticate."}
        isShowErrorOnly={true}
        error={error}
      />
    );
  }

  if (!data || !data.me) {
    redirect("/login", router);
  }

  if (isValidRole(data?.me?.role as Role, permission)) {
    return <>{children}</>;
  }

  if (redirectTo) {
    redirect(redirectTo, router);
  }

  return showError ? (
    <RecordNotFound
      title={
        "Oops! Your account doesnt have permission to access this page/funtionality. Kindly contact administrator for help."
      }
    />
  ) : null;
};

export default AccessRight;
