import React from "react";
import PropTypes from "prop-types";
import { ApolloError, useQuery } from "@apollo/client";
import { meQuery } from "../graphql/queries/user/me";
import { MeQuery } from "../generated/apolloComponents";

type ContextProps = {
  loading: boolean;
  data: MeQuery;
  error: ApolloError;
};

const MeContext = React.createContext<Partial<ContextProps>>({});

export const MeProvider = (props: any) => {
  const { loading, data, error } = useQuery<MeQuery>(meQuery);
  // const user = loading ? null : data.user;

  return (
    <MeContext.Provider
      value={{
        loading,
        data,
        error,
      }}
    >
      {props.children}
    </MeContext.Provider>
  );
};
export const MeConsumer = MeContext.Consumer;
export default MeContext;

MeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
