import * as React from "react";
import { Theme } from "@mui/material/styles";

import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import MyLink from "../MyLink";

type Props = {
  className?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(0, 4, 4),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}));

const Footer: React.FunctionComponent<React.PropsWithChildren<Props>> = (
  props
) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  return (
    <footer {...rest} className={clsx(classes.root, className)}>
      <Divider className={classes.divider} />
      <Typography variant="body1">
        &copy;{" "}
        <MyLink
          href={process.env.NEXT_PUBLIC_MAIN_WEBSITE_LINK || ""}
          target="_blank"
        >
          <span>{process.env.NEXT_PUBLIC_COMPANY_NAME}</span>
        </MyLink>
        {" " + new Date().getFullYear()}
      </Typography>
      <Typography variant="caption">
        Created with love for the environment. By designers and developers who
        love to work together in offices!
      </Typography>
    </footer>
  );
};

export default Footer;
