import * as React from "react";
import Link from "next/link";
import MaterialLink from "@mui/material/Link";

interface Props extends React.PropsWithChildren {
  href?: string;
  as?: string;
  prefetch?: boolean;
  replace?: boolean;
  scroll?: boolean;
  classes?: any;
  component?: any;
  variant?: any;
  color?: any;
  underline?: any;
  target?: any;
  goBack?: any;
}

const MyLink: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  href,
  classes,
  component,
  variant,
  color,
  underline,
  target,
  goBack,
  ...props
}) =>
  href && !goBack ? (
    <Link href={href} {...props}>
      <MaterialLink
        href={href}
        classes={classes}
        component={component}
        variant={variant}
        color={color}
        target={target}
        underline={underline}
      >
        {props.children}
      </MaterialLink>
    </Link>
  ) : (
    <MaterialLink
      href={href}
      classes={classes}
      component={component}
      variant={variant}
      color={color}
      target={target}
      underline={underline}
      onClick={() => goBack()}
      style={{ cursor: "pointer" }}
    >
      {props.children}
    </MaterialLink>
  );

export default MyLink;
