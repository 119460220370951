import * as React from "react";

import { Theme } from "@mui/material/styles";

import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

import Dashboard from "@mui/icons-material/Dashboard";
import People from "@mui/icons-material/People";
import AccessAlarm from "@mui/icons-material/AccessAlarm";
import AccountBox from "@mui/icons-material/AccountBox";
import Settings from "@mui/icons-material/Settings";
import PieChart from "@mui/icons-material/PieChart";
import PunchClock from "@mui/icons-material/PunchClock";

import makeStyles from "@mui/styles/makeStyles";
import SidebarNav from "./SidebarNav";
import clsx from "clsx";
import Profile from "./Profile";
import { Permission } from "../../../interfaces/Permission";
import constants from "../../../constants";

type Props = {
  className?: string;
  onClose?: any;
  open: boolean;
  variant: "permanent" | "persistent" | "temporary" | undefined;
};

type PageProps = {
  title: string;
  href: string;
  icon: JSX.Element;
  permission?: Permission;
};

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    zIndex: theme.zIndex.appBar + 1,
  },
  drawer: {
    width: 240,
    borderRight: "#eeeeee",
    zIndex: theme.zIndex.appBar + 1,
    [theme.breakpoints.up("lg")]: {
      marginTop: 64,
      height: "calc(100% - 64px)",
    },
  },
  root: {
    backgroundColor: theme.palette.common.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}));

const Sidebar: React.FunctionComponent<React.PropsWithChildren<Props>> = (
  props
) => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  const pages: PageProps[] = [
    {
      title: "Dashboard",
      href: "/",
      icon: <Dashboard />,
    },
    {
      title: "Attendances",
      href: "/attendances",
      icon: <AccessAlarm />,
    },

    {
      title: "Users",
      href: "/users",
      icon: <People />,
      permission: {
        action: constants.ROLE_ACTION_ACCOUNT_MANAGEMENT,
        targets: [
          { target: constants.ROLE_TARGET_OTHER, crud: { read: true } },
        ],
      },
    },
    {
      title: "Work Shift",
      href: "/workshifts",
      icon: <PunchClock />,
      permission: {
        action: constants.ROLE_ACTION_WORKSHIFT_MANAGEMENT,
        targets: [
          { target: constants.ROLE_TARGET_OTHER, crud: { read: true } },
        ],
      },
    },
    {
      title: "Reports",
      href: "/reports",
      icon: <PieChart />,
    },
    {
      title: "Account",
      href: "/account",
      icon: <AccountBox />,
      permission: {
        action: constants.ROLE_ACTION_ACCOUNT_MANAGEMENT,
        targets: [{ target: constants.ROLE_TARGET_SELF, crud: { read: true } }],
      },
    },
    {
      title: "Settings",
      href: "/settings",
      icon: <Settings />,
      permission: {
        action: constants.ROLE_ACTION_ACCOUNT_MANAGEMENT,
        targets: [{ target: constants.ROLE_TARGET_SELF, crud: { read: true } }],
      },
    },
  ];

  return (
    <Box component="nav">
      <Drawer
        anchor="left"
        classes={{ paper: classes.drawer }}
        onClose={onClose}
        open={open}
        variant={variant}
      >
        <div {...rest} className={clsx(classes.root, className)}>
          <Profile />
          <Divider className={classes.divider} />
          <SidebarNav className={classes.nav} pages={pages} />
        </div>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
