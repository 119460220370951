import * as React from "react";
import { useState } from "react";
import { Theme } from "@mui/material/styles";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Notifications from "@mui/icons-material/Notifications";
import Menu from "@mui/icons-material/Menu";
import Input from "@mui/icons-material/Input";

import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import MyLink from "../MyLink";

type Props = {
  className?: string;
  onSidebarOpen?: any;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    boxShadow: "none",
  },
  logo: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.3),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    color: theme.palette.common.white,
    [theme.breakpoints.down("lg")]: {
      fontSize: "1rem",
    },
    "& img": {
      marginRight: theme.spacing(0.5),
      height: "40px",
      objectFit: "cover",
      borderRadius: "40px",
      backgroundColor: theme.palette.common.white,
    },
  },
  flexGrow: {
    flexGrow: 1,
  },
  iconButton: {
    color: theme.palette.common.white,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
}));

const Topbar: React.FunctionComponent<React.PropsWithChildren<Props>> = (
  props
) => {
  const { className, onSidebarOpen, ...rest } = props;
  const classes = useStyles();
  const [notifications] = useState([]);

  return (
    <AppBar {...rest} className={clsx(classes.root, className)} component="nav">
      <Toolbar>
        <MyLink href="/">
          <Typography variant="h4" component="span" className={classes.logo}>
            <img alt="Logo" src={process.env.NEXT_PUBLIC_WEBSITE_LOGO_PATH} />
            {process.env.NEXT_PUBLIC_COMPANY_NAME}
          </Typography>
        </MyLink>
        <div className={classes.flexGrow}></div>
        <Box sx={{ display: { lg: "inline-block", xs: "none" } }}>
          <MyLink
            href="/notitfications"
            component={IconButton}
            classes={{ root: classes.iconButton }}
          >
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <Notifications />
            </Badge>
          </MyLink>
          <MyLink
            href="/logout"
            component={IconButton}
            classes={{ root: clsx(classes.iconButton, classes.signOutButton) }}
          >
            <Input />
          </MyLink>
        </Box>
        <Box sx={{ display: { lg: "none", xs: "block" } }}>
          <IconButton
            onClick={onSidebarOpen}
            size="large"
            classes={{ root: classes.iconButton }}
          >
            <Menu />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
