import * as React from "react";
import { Theme } from "@mui/material/styles";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Snackbar from "@mui/material/Snackbar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import makeStyles from "@mui/styles/makeStyles";
import Lottie from "react-lottie";

import animationData from "../../assets/lottie/no-data-found.json";

interface Props extends React.PropsWithChildren {
  title: any;
  error?: any;
  customDisplay?: boolean;
  isShowErrorOnly?: boolean;
  defaultOpen?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  animation: {
    width: "50%",
    height: "auto",
    objectFit: "cover",
    margin: "20px auto 0",
  },
  message: {
    fontSize: theme.typography.h4.fontSize,
    marginBottom: theme.spacing(2),
  },
}));

const RecordNotFound: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = (props) => {
  const {
    title,
    error,
    isShowErrorOnly = false,
    defaultOpen = true,
    customDisplay = false,
  } = props;
  const classes = useStyles();

  const [open, setOpen] = React.useState(defaultOpen);

  const handleClose = (_event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return !isShowErrorOnly ? (
    customDisplay ? (
      <>
        <div className={classes.animation}>
          <Lottie
            isClickToPauseDisabled={true}
            options={{
              loop: true,
              autoplay: true,
              animationData: animationData,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
          />
        </div>
        <Typography variant="h4" align="center" className={classes.message}>
          {title}
          {error ? (
            <Typography variant="body2">{error.message}</Typography>
          ) : undefined}
        </Typography>
        {error ? (
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert severity="error" elevation={6} variant="filled">
              {error.message}
            </Alert>
          </Snackbar>
        ) : undefined}
      </>
    ) : (
      <Card>
        <CardContent>
          <div className={classes.animation}>
            <Lottie
              isClickToPauseDisabled={true}
              options={{
                loop: true,
                autoplay: true,
                animationData: animationData,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
            />
          </div>
          <Typography variant="h4" align="center" className={classes.message}>
            {title}
            {error ? (
              <Typography variant="body2">{error.message}</Typography>
            ) : undefined}
          </Typography>
          {error ? (
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
              <Alert severity="error" elevation={6} variant="filled">
                {error.message}
              </Alert>
            </Snackbar>
          ) : undefined}
        </CardContent>
      </Card>
    )
  ) : error ? (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert severity="error" elevation={6} variant="filled">
        <AlertTitle>Warning</AlertTitle>
        {error.message}
      </Alert>
    </Snackbar>
  ) : null;
};

export default RecordNotFound;
