import gql from "graphql-tag";

export const meQuery = gql`
  query Me {
    me {
      _id
      profile {
        name {
          firstName
          lastName
        }
        birthday
        gender
        phones {
          number
          countryCode
          remarks
        }
        avatar {
          _id
          url
          uniqueFileName
        }
        currentAddress {
          address
          city
          state
          postcode
          country
        }
        originAddress {
          address
          city
          state
          postcode
          country
        }
      }
      department
      email
      workShift {
        title
        monday {
          workStart
          lunchStart
          lunchEnd
          workEnd
          otStart
          otEnd
          workingRate
          otRate
          minWorkingHours
          isFlexible
          isFlexibleTimeLimited
          flexibleMinutesAllowed
        }
        tuesday {
          workStart
          lunchStart
          lunchEnd
          workEnd
          otStart
          otEnd
          workingRate
          otRate
          minWorkingHours
          isFlexible
          isFlexibleTimeLimited
          flexibleMinutesAllowed
        }
        wednesday {
          workStart
          lunchStart
          lunchEnd
          workEnd
          otStart
          otEnd
          workingRate
          otRate
          minWorkingHours
          isFlexible
          isFlexibleTimeLimited
          flexibleMinutesAllowed
        }
        thursday {
          workStart
          lunchStart
          lunchEnd
          workEnd
          otStart
          otEnd
          workingRate
          otRate
          minWorkingHours
          isFlexible
          isFlexibleTimeLimited
          flexibleMinutesAllowed
        }
        friday {
          workStart
          lunchStart
          lunchEnd
          workEnd
          otStart
          otEnd
          workingRate
          otRate
          minWorkingHours
          isFlexible
          isFlexibleTimeLimited
          flexibleMinutesAllowed
        }
        saturday {
          workStart
          lunchStart
          lunchEnd
          workEnd
          otStart
          otEnd
          workingRate
          otRate
          minWorkingHours
          isFlexible
          isFlexibleTimeLimited
          flexibleMinutesAllowed
        }
        sunday {
          workStart
          lunchStart
          lunchEnd
          workEnd
          otStart
          otEnd
          workingRate
          otRate
          minWorkingHours
          isFlexible
          isFlexibleTimeLimited
          flexibleMinutesAllowed
        }
        holiday {
          workStart
          lunchStart
          lunchEnd
          workEnd
          otStart
          otEnd
          workingRate
          otRate
          minWorkingHours
          isFlexible
          isFlexibleTimeLimited
          flexibleMinutesAllowed
        }
        status
        createdAt
        updatedAt
        _id
        isAssignOnly
      }
      role {
        _id
        name
        permissions {
          accountManagement {
            self {
              create
              read
              update
              delete
            }
            other {
              create
              read
              update
              delete
            }
          }
          fileManagement {
            self {
              create
              read
              update
              delete
            }
            other {
              create
              read
              update
              delete
            }
          }
          notificationManagement {
            self {
              create
              read
              update
              delete
            }
            other {
              create
              read
              update
              delete
            }
          }
          announcementManagement {
            self {
              create
              read
              update
              delete
            }
            other {
              create
              read
              update
              delete
            }
          }
          roleManagement {
            self {
              create
              read
              update
              delete
            }
            other {
              create
              read
              update
              delete
            }
          }
          attendanceManagement {
            self {
              create
              read
              update
              delete
            }
            other {
              create
              read
              update
              delete
            }
          }
          workShiftManagement {
            self {
              create
              read
              update
              delete
            }
            other {
              create
              read
              update
              delete
            }
          }
          clockLocationManagement {
            self {
              create
              read
              update
              delete
            }
            other {
              create
              read
              update
              delete
            }
          }
        }
      }
    }
  }
`;
