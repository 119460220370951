import * as React from "react";
import { Theme } from "@mui/material/styles";

import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import CircularProgress from "@mui/material/CircularProgress";

import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import MyLink from "../MyLink";
import { MeConsumer } from "../../../context/MeContext";
import { stringToColor } from "../../../lib/randomColor";

type Props = {
  className?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content",
  },
  avatar: {
    width: 60,
    height: 60,
  },
  name: {
    marginTop: theme.spacing(1),
  },
}));

const Profile: React.FunctionComponent<React.PropsWithChildren<Props>> = (
  props
) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest}>
      <MeConsumer>
        {({ loading, data }) => (
          <div>
            {loading ? (
              <CircularProgress />
            ) : data && data.me ? (
              <MyLink
                href="/account"
                classes={{ root: clsx(classes.root, className) }}
                underline="none"
              >
                {data.me.profile.avatar ? (
                  <Avatar
                    alt="Person"
                    className={classes.avatar}
                    src={data.me.profile.avatar.url}
                    variant="circular"
                  />
                ) : (
                  <Avatar
                    style={{
                      backgroundColor: stringToColor(
                        data.me.profile.name.firstName
                      ),
                    }}
                  >
                    {data.me.profile.name.firstName.charAt(0)}
                  </Avatar>
                )}

                <Typography className={classes.name} variant="h4">
                  {data.me.profile.name.firstName}
                </Typography>
                <Typography variant="body2">Edit Profile</Typography>
              </MyLink>
            ) : undefined}
          </div>
        )}
      </MeConsumer>
    </div>
  );
};

export default Profile;
