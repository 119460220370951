const generateMetaTitle = (title: string): string => {
  return (
    title +
    " " +
    process.env.NEXT_PUBLIC_WEBSITE_SEPERATOR +
    " " +
    process.env.NEXT_PUBLIC_WEBSITE_NAME
  );
};

export default generateMetaTitle;
